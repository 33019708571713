/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'preguntas_frecuentes': {
      init: function() {
        // JavaScript to be fired on the about us page

        $('.panel-collapse').on('show.bs.collapse', function () {
          $(this).siblings('.panel-heading').addClass('active');
        });
      
        $('.panel-collapse').on('hide.bs.collapse', function () {
          $(this).siblings('.panel-heading').removeClass('active');
        });
        
      }
    },

    // About us page, note the change from about-us to about_us.
    'productos': {
      init: function() {
        // JavaScript to be fired on the about us page

          // external js: isotope.pkgd.js

          // filter functions
          var filterFns = {
            // show if number is greater than 50
            numberGreaterThan50: function() {
              var number = $(this).find('.number').text();
              return parseInt( number, 10 ) > 50;
            },
            // show if name ends with -ium
            ium: function() {
              var name = $(this).find('.name').text();
              return name.match( /ium$/ );
            }
          };

          function getHashFilter() {
            // get filter=filterName
            var matches = location.hash.match( /filter=([^&]+)/i );
            var hashFilter = matches && matches[1];
            return hashFilter && decodeURIComponent( hashFilter );
          }

          // init Isotope
          var $grid = $('.grid');

          // bind filter button click
          var $filterButtonGroup = $('.filter-button-group');
          $filterButtonGroup.on( 'click', 'button', function() {
            var filterAttr = $( this ).attr('data-filter');
            // set filter in hash
            location.hash = 'filter=' + encodeURIComponent( filterAttr );
          });

          var isIsotopeInit = false;

          function onHashchange() {
            var hashFilter = getHashFilter();
            if ( !hashFilter && isIsotopeInit ) {
              return;
            }
            isIsotopeInit = true;
            // filter isotope
            $grid.isotope({
              itemSelector: '.element-item',
              layoutMode: 'fitRows',
              // use filterFns
              filter: filterFns[ hashFilter ] || hashFilter
            });
            // set selected class on button
            if ( hashFilter ) {
              $filterButtonGroup.find('.is-checked').removeClass('is-checked');
              $filterButtonGroup.find('[data-filter="' + hashFilter + '"]').addClass('is-checked');
            }
          }

          $(window).on( 'hashchange', onHashchange );

          // trigger event handler to init Isotope
          onHashchange();


        
      }
    }




  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
